import React from "react";
import NavigationBar from "../components/NavigationBar";
import Layout from "../components/Layout";
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image";
import Footer from "../components/Footer";

const NotFoundPage = () => {
    const imageData = useStaticQuery(graphql`
    {
        errorImage: file(
        extension: {eq: "jpeg"},
        name: {eq: "dog-laptop"}) {
        childImageSharp {
          fluid {
            src
            srcSet
            sizes
            srcSetWebp
          }
        }
      }
    }
    `)

    return (
        <div className="w-full h-screen">
            <Layout>
                <NavigationBar></NavigationBar>
            </Layout>
            <Img className="absolute top-0 left-0 object-contain w-screen h-full max-h-[30rem] opacity-75" fluid={imageData.errorImage.childImageSharp.fluid} alt="Dog looking at laptop trying his hardest to find the page you were looking for." />
            <h1 className="text-center text-6xl font-bold text-salsa drop-shadow-md absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">404: Page Not Found</h1>
            <Footer/>
        </div>

    )
}

export default NotFoundPage;
