import React from "react"
import { Link } from 'gatsby';

import flatpackLogo from '../images/logo-box.svg';

function NavigationBar () {
    const [isOpen, setisOpen] = React.useState(false);

    function handleClick() {
        setisOpen(!isOpen);
    }

    return (
        <nav className="px-4 pt-8 pb-12">
        <div className="flex justify-between items-center">
            <div className="flex items-center">
                <Link to="/" className="text-4xl font-bold no-underline leading-none text-primary ">FlatPack FinTech</Link>
            </div>

            <button className="desktop:hidden" onClick={handleClick}>
                {isOpen && (<i className="fa-solid fa-xmark text-3xl menu-button"></i>)}
                {!isOpen && (<i className="fa-solid fa-bars text-2xl"></i>)}
            </button>

            {/* Desktop Links */}
            <ul className="hidden desktop:flex items-center w-auto space-x-12 whitespace-nowrap">
                <li><Link to="/articles/" className="text-xl no-underline font-semibold hover:text-teal">Articles</Link></li>
                <li><Link to="/courses/" className="text-xl no-underline font-semibold hover:text-teal">Courses</Link></li>
                <li><Link to="/log-in/" className="text-xl no-underline font-semibold hover:text-teal">Log In</Link></li>
                <li><Link to="/sign-up/" className="text-xl no-underline font-semibold text-yellow hover:text-white">
                    <button className="bg-midnight px-4 py-2 rounded rounded-lg">Sign Up</button>
                </Link></li>
            </ul>
        </div>
        {/* Mobile Menu */}
        <div className={`desktop:hidden space-y-4 mt-4 ${isOpen ? "flex flex-col" : "hidden"}`}>
            <ul className="bg-primary rounded-lg w-full text-center">
                <Link to="/articles/">
                    <li className="py-2 font-bold text-white hover:text-blue border-b-[1px] border-white">Articles</li>
                </Link>
                <Link to="/courses/">
                    <li className="py-2 font-bold text-white hover:text-blue border-b-[1px] border-white">Courses</li>
                </Link>
                <Link to="/">
                    <li className="py-2 font-bold text-white hover:text-blue border-b-[1px] border-white">Log In
                    </li>
                </Link>
                <Link to="/">
                    <li className="py-2 font-bold text-yellow hover:text-blue">Sign Up</li>
                </Link>
            </ul>
        </div>
    </nav>
    );
}

export default NavigationBar;