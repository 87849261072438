import React from "react";
import "../styles/global.css";


function Layout({children}){
    return (
        <div className="min-w-screen px-4 tablet:px-24 desktop:px-52">
            {children}
        </div>
    )
}

export default Layout